import { Controller } from "@hotwired/stimulus"

// This controller handles updating the hidden quantity field when the select changes
export default class extends Controller {
  static targets = ["select", "hidden"]

  connect() {
    this.updateHiddenValue()
  }

  updateHiddenValue() {
    if (this.hasSelectTarget && this.hasHiddenTarget) {
      this.hiddenTarget.value = this.selectTarget.value
    }
  }
}
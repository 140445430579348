import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = [
    "UntreatedChoiceInput", "TreatedChoiceInput",
    "ShippingAddressContainer",
    "ShippingNameInput", "ShippingAddressInput", "ShippingCityInput", "ShippingStateInput", "ShippingZipcodeInput",
    "BillingNameInput", "BillingAddressInput", "BillingCityInput", "BillingStateInput", "BillingZipcodeInput",
    "ShippingSameAsBillingInput",
    "SummaryOrderItemsContainer", "SummaryOrderItem", "SummaryOrderItemPrice", "SummarySubtotal", "SummaryShipping", "SummaryPalletFee", "SummaryCreditCardLine", "SummaryCreditCardAmount", "SummaryTotalAmount",
    "MailCheckInput", "AchInput", "CreditCardInput", "JdfInput", "AffirmInput",
    "CreditCardLabel", "JdfLabel", "JdfMinimumOrderSizeNotice", "AffirmLabel", "AffirmPromoContainer",
    "NoCreditCardMessage", "MailCheckInstructionText", "AchInstructionText", "CreditCardInstructionText", "AdminCantFwdCreditCardFee", "JdfInstructionText", "OrdersFinalNotice", "SubmitButton",
    "NoUntreatedAlert", "NoTreatedAlert", "FungOnlyAlert", "OrganicLowPurityAlert", "NonGmoLowPurityAlert",
    "NonGmoPuritySelectInput", "NonGmoPurityGeneralUseAlert",  "NonGmoPurityHighPurityAlert",
  ]

  static values = {
    canUseCardFee: Boolean,
    adminUser: Boolean,
    setPaymentMethodPath: String,
    earlySeasonPromoChoice: String
  }

  connect() {
    this.statesNoCardFee = ['CA', 'CO', 'CT', 'FL', 'KS', 'ME', 'MA', 'NY', 'OK', 'TX']
    this.jdfAddedCostPercent = 1.0625
    this.currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    this.tryHideCardPaymentOption()
    this.tryDisableJdfPaymentOption()
    this.initPaymentMethodDisplay()
    this.initCustomerBillingFields()
    this.handleLocalPickupAdTracking()

    if (this.hasAffirmInputTarget) {
      if (window.affirm) {
        window.affirm.ui.ready(function(){
        window.affirm.ui.refresh();
        });
      }
    }
  }

  initCustomerBillingFields() {
    if (Cookies.get('admin_selected_customer')) {
      const customer = JSON.parse(Cookies.get('admin_selected_customer'))

      const order = customer.most_recent_order
      let orderDetails = `Customer Page Link <a href="https://api.covercropexchange.com/admin/customers/${customer.id}" style="text-decoration: underline;" target="_blank">here</a><br>`;
      orderDetails += `Order Id: <a href="https://api.covercropexchange.com/admin/orders/${order.id}" style="text-decoration: underline;" target="_blank">${order.id}</a><br>`;
      orderDetails += `Seed Treated?: ${order.untreated_choice ? 'No' : 'Yes'}<br>`;
      orderDetails += `Organic?: ${order.certified_organic_market ? 'Yes' : 'No'}<br>`;
      orderDetails += `High Purity Market: ${order.non_gmo_purity_selection == "false" ? 'No' : 'Yes/Maybe?'}<br>`;
      orderDetails += `Notes: ${order.notes ? order.notes : ''}<br>`;
      orderDetails += `Email: ${order.email}<br>`;
      orderDetails += `Phone: ${order.phone_number}<br>`;
      orderDetails += `Billing Name: ${order.billing_name}<br>`;
      orderDetails += `Company Name: ${order.company_name}<br>`;
      orderDetails += `Billing Address: ${order.billing_address}<br>`;
      orderDetails += `Billing City: ${order.billing_city}<br>`;
      orderDetails += `Billing State: ${order.billing_state}<br>`;
      orderDetails += `Billing Zipcode: ${order.billing_zipcode}<br>`;
      orderDetails += `Shipping Same as Billing?: ${order.shipping_same_as_billing}<br>`;
      orderDetails += `Shipping Name: ${order.shipping_name}<br>`;
      orderDetails += `Shipping Address: ${order.shipping_address}<br>`;
      orderDetails += `Shipping City: ${order.shipping_city}<br>`;
      orderDetails += `Shipping State: ${order.shipping_state}<br>`;
      orderDetails += `Shipping Zipcode: ${order.shipping_zipcode}<br>`;
      orderDetails += `Payment Method: ${order.payment_method}`;
      document.getElementById('billing-previous-order-data').innerHTML = orderDetails
      document.getElementById('billing-previous-order-data').classList.remove('hidden')
      document.getElementById('order_email').value = order.email
      document.getElementById('order_email_confirmation').value = order.email
      document.getElementById('order_phone_number').value = order.phone_number
      document.getElementById('order_billing_name').value = order.billing_name
      document.getElementById('order_company_name').value = order.company_name
      document.getElementById('order_billing_address').value = order.billing_address
      document.getElementById('order_billing_city').value = order.billing_city
      document.getElementById('order_billing_state').value = order.billing_state
      document.getElementById('order_billing_zipcode').value = order.billing_zipcode
      if (order.shipping_same_as_billing) {
        document.getElementById('order_shipping_same_as_billing').checked = true
        let element = document.getElementById('order_shipping_same_as_billing');
        let event = new Event('change');
        element.dispatchEvent(event);
      } else {
        document.getElementById('order_shipping_name').value = order.shipping_name
        document.getElementById('order_shipping_address').value = order.shipping_address
        document.getElementById('order_shipping_city').value = order.shipping_city
        document.getElementById('order_shipping_state').value = order.shipping_state
        document.getElementById('order_shipping_zipcode').value = order.shipping_zipcode
      }
    }
  }

  handleLocalPickupAdTracking() {
    const deliveryMethod = Cookies.get('delivery_method');
    if (deliveryMethod === 'pickup') {
      if (window.dataLayer) {
        window.dataLayer.push({
          'event': 'RequestedShipping',
          'conversionValue': 15.00
        });
      }
    }
  }

  onUntreatedChoiceChange(e) {
    if (this.hasNoUntreatedAlertTarget) {
      if (e.target.value == "true") {
        this.NoUntreatedAlertTarget.classList.remove("hidden")
      } else {
        this.NoUntreatedAlertTarget.classList.add("hidden")
      }
    }
    if (this.hasNoTreatedAlertTarget) {
      if (e.target.value == "false") {
        this.NoTreatedAlertTarget.classList.remove("hidden")
      } else {
        this.NoTreatedAlertTarget.classList.add("hidden")
      }
    }
    if (this.hasFungOnlyAlertTarget) {
      if (e.target.value == "false") {
        this.FungOnlyAlertTarget.classList.remove("hidden")
      } else {
        this.FungOnlyAlertTarget.classList.add("hidden")
      }
    }
  }

  onCertifiedOrganicMarketChange(e) {
    if (e.target.value == "true") {
      if (this.hasUntreatedChoiceInputTarget && !this.UntreatedChoiceInputTarget.disabled) {
        this.UntreatedChoiceInputTarget.checked = true
        this.UntreatedChoiceInputTarget.dispatchEvent(new Event('click'))
        this.UntreatedChoiceInputTarget.disabled = true
      }
      if (this.hasTreatedChoiceInputTarget && !this.TreatedChoiceInputTarget.disabled) {
        this.TreatedChoiceInputTarget.disabled = true
      }
      this.NonGmoPuritySelectInputTarget.value = this.NonGmoPuritySelectInputTarget.options[this.NonGmoPuritySelectInputTarget.options.length - 1].value
      this.NonGmoPuritySelectInputTarget.disabled = true
      this.NonGmoPuritySelectInputTarget.dispatchEvent(new Event('change'))
      if (this.OrganicLowPurityAlertTargets.length > 0) {
        this.OrganicLowPurityAlertTargets.forEach((target) => {
          target.classList.remove("hidden")
        })
      }
    } else {
      if (this.hasUntreatedChoiceInputTarget && !this.UntreatedChoiceInputTarget.disabled) {
        this.UntreatedChoiceInputTarget.disabled = false
      }
      if (this.hasTreatedChoiceInputTarget && !this.TreatedChoiceInputTarget.disabled) {
        this.TreatedChoiceInputTarget.disabled = false
      }
      this.NonGmoPuritySelectInputTarget.disabled = false
      if (this.OrganicLowPurityAlertTargets.length > 0) {
        this.OrganicLowPurityAlertTargets.forEach((target) => {
          target.classList.add("hidden")
        })
      }
    }
  }

  onNonGmoPuritySelectionChange(e) {
    if (e.target.value.includes("General Use")) {
      this.NonGmoPurityGeneralUseAlertTarget.classList.remove("hidden")
      this.NonGmoPurityHighPurityAlertTarget.classList.add("hidden")
      this.OrganicLowPurityAlertTargets.forEach((target) => {
        target.classList.add("hidden");
      })
    } else if (e.target.value.includes("High Purity")) {
      this.NonGmoPurityGeneralUseAlertTarget.classList.add("hidden")
      this.NonGmoPurityHighPurityAlertTarget.classList.remove("hidden")
      this.OrganicLowPurityAlertTargets.forEach((target) => {
        target.classList.remove("hidden");
      })
    } else {
      this.NonGmoPurityGeneralUseAlertTarget.classList.add("hidden")
      this.NonGmoPurityHighPurityAlertTarget.classList.add("hidden")
      this.OrganicLowPurityAlertTargets.forEach((target) => {
        target.classList.add("hidden");
      })
    }

    this.persistNonGmoPuritySelection(e)
  }

  async persistNonGmoPuritySelection(e) {
    const puritySelection = e.target.value
    const formPath = e.target.dataset.setValuePath

    const request = new FetchRequest('PUT', formPath, { body: JSON.stringify({non_gmo_purity_selection: puritySelection}), responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      const orderItems = responseJson.order.order_items
      orderItems.map((oi) => {
        if (this.SummaryOrderItemTargets.find((soit) => soit.dataset.orderItemId == oi.id)) {
          // do nothing
        } else {
          // add order item to summary
          const newSummaryOrderItem = document.createElement('div')
          newSummaryOrderItem.classList.add("mt-1", "flex", "items_center", "justify-between", "mb-2", "text-sm")
          newSummaryOrderItem.dataset.orderItemId = oi.id
          newSummaryOrderItem.dataset.billingTarget = "SummaryOrderItem"
          const newSummaryOrderItemTitle = document.createElement('span')
          newSummaryOrderItemTitle.innerHTML = `${oi.quantity} x ${oi.title} - ${oi.is_discounted ? "<span style='color:red; text-decoration:line-through;'><span class='text-gray-900'>" + this.currencyFormatter.format(oi.monetized_unit_price) + "</span></span> " : this.currencyFormatter.format(oi.unit_price)}/bag`
          const newSummaryOrderItemPrice = document.createElement('span')
          newSummaryOrderItemPrice.classList.add("ml-8")
          newSummaryOrderItemPrice.dataset.billingTarget = "SummaryOrderItemPrice"
          newSummaryOrderItemPrice.innerHTML = this.currencyFormatter.format(oi.total_price)
          newSummaryOrderItem.appendChild(newSummaryOrderItemTitle)
          newSummaryOrderItem.appendChild(newSummaryOrderItemPrice)
          this.SummaryOrderItemsContainerTarget.appendChild(newSummaryOrderItem)
        }
      })

      this.SummaryOrderItemTargets.map((soit) => {
        if (orderItems.find((oi) => oi.id == soit.dataset.orderItemId)) {
          // do nothing
        } else {
          // remove order item from summary
          soit.remove()
        }
      })

      const newSubtotal = responseJson.order.subtotal
      this.SummarySubtotalTarget.innerHTML = this.currencyFormatter.format(newSubtotal)

      const newTotal = responseJson.order.total
      this.SummaryTotalAmountTarget.innerHTML = this.currencyFormatter.format(newTotal)
    } else {
      const responseJson = await response.json

      alert("There was an error updating the purity selection. Please try again or contact us for support")
    }
  }

  onShippingSameAsBillingChange(e) {
    if (e.target.checked) {
      this.ShippingAddressContainerTarget.classList.remove("visible", "opacity-100", "h-auto")
      this.ShippingAddressContainerTarget.classList.add("invisible", "opacity-0", "h-0")
      this.copyBillingToShipping()
    } else {
      this.ShippingAddressContainerTarget.classList.remove("invisible", "opacity-0", "h-0")
      this.ShippingAddressContainerTarget.classList.add("visible", "opacity-100", "h-auto")
      this.clearShippingFields()
    }
  }

  onBillingFieldChange() {
    if (this.ShippingSameAsBillingInputTarget.checked) {
      this.copyBillingToShipping()
    }
  }

  onBillingStateChange(e) {
    if (this.statesNoCardFee.indexOf(e.target.value) >= 0) {
      this.hideCreditCardFee()
      this.hideCreditCardPaymentOption()
      this.canUseCardFeeValue = false
    } else {
      this.canUseCardFeeValue = true
      this.showCreditCardPaymentOption()
    }

    this.onBillingFieldChange()
  }

  onPaymentMethodChange(e) {
    let paymentMethod = null

    if (e.currentTarget.dataset.billingTarget == "MailCheckInput") {
      this.showInstructionTextFor("check")
      this.hideCreditCardFee()
      this.showOrdersFinalNotice()
      if (this.hasJdfMinimumOrderSizeNoticeTarget) {
        this.JdfMinimumOrderSizeNoticeTarget.classList.add("hidden")
      }
      paymentMethod = "check"
    }
    if (e.currentTarget.dataset.billingTarget == "AchInput") {
      this.showInstructionTextFor("ach")
      this.hideCreditCardFee()
      this.showOrdersFinalNotice()
      if (this.hasJdfMinimumOrderSizeNoticeTarget) {
        this.JdfMinimumOrderSizeNoticeTarget.classList.add("hidden")
      }
      paymentMethod = "ach"
    }
    if (e.currentTarget.dataset.billingTarget == "CreditCardInput") {
      this.showInstructionTextFor("credit_card")
      this.showCreditCardFee()
      this.hideOrdersFinalNotice()
      if (this.hasJdfMinimumOrderSizeNoticeTarget) {
        this.JdfMinimumOrderSizeNoticeTarget.classList.add("hidden")
      }
      paymentMethod = "credit_card"
    }
    if (e.currentTarget.dataset.billingTarget == "JdfInput") {
      if (this.adminUserValue && this.totalAmount() < 1000.00) {
        const confirmed = confirm("This order total is less than $1000. The minimum order amount for JDF financing is typically $1000. Are you sure you want to select JDF payment?");
        if (!confirmed) {
          this.AchInputTarget.checked = true;
          this.showInstructionTextFor("ach");
          this.hideCreditCardFee();
          this.showOrdersFinalNotice();
          if (this.hasJdfMinimumOrderSizeNoticeTarget) {
            this.JdfMinimumOrderSizeNoticeTarget.classList.add("hidden");
          }
          paymentMethod = "ach";
          this.savePaymentMethodOnOrder(paymentMethod);
          return;
        }
      }

      this.showInstructionTextFor("jdf")
      this.hideCreditCardFee()
      this.hideOrdersFinalNotice()
      if (this.hasJdfMinimumOrderSizeNoticeTarget) {
        this.JdfMinimumOrderSizeNoticeTarget.classList.remove("hidden")
      }
      paymentMethod = "jdf"
    }
    if (e.currentTarget.dataset.billingTarget == "AffirmInput") {
      this.showInstructionTextFor("affirm")
      this.hideCreditCardFee()
      this.hideOrdersFinalNotice()
      if (this.hasJdfMinimumOrderSizeNoticeTarget) {
        this.JdfMinimumOrderSizeNoticeTarget.classList.add("hidden")
      }
      paymentMethod = "affirm"
    }

    this.savePaymentMethodOnOrder(paymentMethod)
  }

  showInstructionTextFor(payment_type) {
    if (payment_type == "check") {
      this.MailCheckInstructionTextTarget.classList.remove("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
    } else if (payment_type == "ach") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.remove("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
    } else if (payment_type == "credit_card") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.remove("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
    } else if (payment_type == "jdf") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.remove("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
    } else if (payment_type == "affirm") {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      this.JdfInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.remove("hidden")
      }
    }
  }

  tryHideCardPaymentOption() {
    if (this.canUseCardFeeValue == false && this.statesNoCardFee.indexOf(this.ShippingStateInputTarget.value >= 0)) {
      this.hideCreditCardPaymentOption()
    }
  }

  tryDisableJdfPaymentOption() {
    if (this.totalAmount() < 1000.00 && !this.adminUserValue) {
      if (this.JdfInputTarget.checked) {
        this.AchInputTarget.checked = true
      }
      this.JdfInputTarget.disabled = true
      if (this.earlySeasonPromoChoiceValue == "financing" && this.hasJdfMinimumOrderSizeNoticeTarget) {
        this.JdfMinimumOrderSizeNoticeTarget.classList.remove("hidden")
      }
      this.JdfInstructionTextTarget.classList.add("hidden")
      this.JdfInputTarget.classList.add("cursor-not-allowed")
      this.JdfLabelTarget.classList.add("cursor-not-allowed")
    }
  }

  initPaymentMethodDisplay() {
    if (this.hasMailCheckInputTarget && this.MailCheckInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.remove("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
      this.hideCreditCardFee()
    }
    if (this.hasAchInputTarget && this.AchInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.remove("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
      this.hideCreditCardFee()
    }
    if (this.hasCreditCardInputTarget && this.CreditCardInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.remove("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.add("hidden")
      }
      this.showCreditCardFee()
    }
    if (this.hasAffirmInputTarget && this.AffirmInputTarget.checked) {
      this.MailCheckInstructionTextTarget.classList.add("hidden")
      this.AchInstructionTextTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      if (this.hasAffirmPromoContainerTarget) {
        this.AffirmPromoContainerTarget.classList.remove("hidden")
      }
      this.hideCreditCardFee()
    }
  }

  copyBillingToShipping() {
    this.ShippingNameInputTarget.value = this.BillingNameInputTarget.value
    this.ShippingAddressInputTarget.value = this.BillingAddressInputTarget.value
    this.ShippingCityInputTarget.value = this.BillingCityInputTarget.value
    this.ShippingStateInputTarget.value = this.BillingStateInputTarget.value
    this.ShippingZipcodeInputTarget.value = this.BillingZipcodeInputTarget.value
  }

  clearShippingFields() {
    this.ShippingNameInputTarget.value = ""
    this.ShippingAddressInputTarget.value = ""
    this.ShippingCityInputTarget.value = ""
    this.ShippingStateInputTarget.value = ""
    this.ShippingZipcodeInputTarget.value = ""
  }

  hideCreditCardPaymentOption() {
    if (!this.adminUserValue) {
      this.CreditCardInputTarget.disabled = true
      this.CreditCardLabelTarget.classList.add("opacity-50", "cursor-not-allowed")
      if (this.CreditCardInputTarget.checked) {
        this.CreditCardInputTarget.checked = false
        this.AchInputTarget.checked = true
      }
      this.NoCreditCardMessageTarget.classList.remove("hidden")
      this.AchInstructionTextTarget.classList.remove("hidden")
      this.canUseCardFeeValue = false
    } else {
      if (this.hasAdminCantFwdCreditCardFeeTarget) {
        this.AdminCantFwdCreditCardFeeTarget.classList.remove("hidden")
      }
    }
  }

  showCreditCardPaymentOption() {
    this.CreditCardInputTarget.disabled = false
    this.CreditCardLabelTarget.classList.remove("opacity-50", "cursor-not-allowed")
    this.NoCreditCardMessageTarget.classList.add("hidden")
    this.canUseCardFeeValue = true
  }

  showOrdersFinalNotice() {
    this.OrdersFinalNoticeTarget.classList.remove("hidden")
  }

  hideOrdersFinalNotice() {
    this.OrdersFinalNoticeTarget.classList.add("hidden")
  }

  hideCreditCardFee() {
    if ((this.adminUserValue || this.canUseCardFeeValue == true) && Array.from(this.SummaryCreditCardLineTarget.classList).indexOf("hidden") < 0) {
      this.SummaryCreditCardLineTarget.classList.add("hidden")
      this.CreditCardInstructionTextTarget.classList.add("hidden")
      var newTotal = this.totalAmount() - this.cardFeeAmount()
      this.SummaryTotalAmountTarget.innerHTML = `$${Number(+(Math.round(newTotal + "e+2") + "e-2")).toFixed(2)}`
      this.SubmitButtonTarget.value = "Submit Order"
    }
  }

  showCreditCardFee() {
    const shippingState = this.ShippingStateInputTarget.value
    const billingState = this.BillingStateInputTarget.value
    const billingStateCantFwdFee = billingState !== "" && this.statesNoCardFee.indexOf(billingState) >= 0
    const shippingStateCantFwdFee = shippingState !== "" && this.statesNoCardFee.indexOf(shippingState) >= 0

    if (this.adminUserValue || (billingState && !billingStateCantFwdFee) || (shippingState && !shippingStateCantFwdFee)) {
      if (this.adminUserValue || (this.canUseCardFeeValue == true && Array.from(this.SummaryCreditCardLineTarget.classList).includes("hidden"))) {
        this.SummaryCreditCardLineTarget.classList.remove("hidden")
        this.CreditCardInstructionTextTarget.classList.remove("hidden")
        var newTotal = this.totalAmount() + this.cardFeeAmount()
        this.SummaryTotalAmountTarget.innerHTML = `$${Number(+(Math.round(newTotal + "e+2") + "e-2")).toFixed(2)}`
        this.SubmitButtonTarget.value = "Finish & Pay"
      }
    }
  }

  totalAmount() {
    var totalAmount = this.SummaryTotalAmountTarget.textContent
    totalAmount = totalAmount.replace("$", "").replace(",", "")
    return parseFloat(totalAmount)
  }

  cardFeeAmount() {
    var feeAmount = this.SummaryCreditCardAmountTarget.textContent
    feeAmount = feeAmount.replace("$", "").replace(",", "")
    return parseFloat(feeAmount)
  }

  async savePaymentMethodOnOrder(paymentMethod) {
    const request = new FetchRequest('PUT', this.setPaymentMethodPathValue, { body: JSON.stringify({payment_method: paymentMethod}), responseKind: 'json' })

    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      const orderItems = responseJson.order.order_items
      orderItems.map((oi) => {
        if (this.SummaryOrderItemTargets.find((soit) => soit.dataset.orderItemId == oi.id)) {
          if (oi.regen_pack_data && oi.regen_pack_data.order_item_id && oi.id == oi.regen_pack_data.order_item_id) {
            const orderItemTarget = this.SummaryOrderItemTargets.find((soit) => soit.dataset.orderItemId == oi.id)
            const newSummaryOrderItemTitle = orderItemTarget.querySelector("span:first-child");
            newSummaryOrderItemTitle.innerHTML = `${oi.quantity} x ${oi.title} - ${oi.is_discounted ? "<span style='color:red; text-decoration:line-through;'><span class='text-gray-900'>" + this.currencyFormatter.format(oi.monetized_unit_price) + "</span></span> " : this.currencyFormatter.format(oi.unit_price)}/bag`
            const orderItemPriceTarget = orderItemTarget.querySelector("[data-billing-target='SummaryOrderItemPrice']")
            orderItemPriceTarget.innerHTML = this.currencyFormatter.format(oi.total_price)
          } else {
            // do nothing
          }
        } else {
          // add order item to summary
          const newSummaryOrderItem = document.createElement('div')
          newSummaryOrderItem.classList.add("mt-1", "flex", "items_center", "justify-between", "mb-2", "text-sm")
          newSummaryOrderItem.dataset.orderItemId = oi.id
          newSummaryOrderItem.dataset.billingTarget = "SummaryOrderItem"
          const newSummaryOrderItemTitle = document.createElement('span')
          newSummaryOrderItemTitle.innerHTML = `${oi.quantity} x ${oi.title} - ${oi.is_discounted ? "<span style='color:red; text-decoration:line-through;'><span class='text-gray-900'>" + this.currencyFormatter.format(oi.monetized_unit_price) + "</span></span> " : this.currencyFormatter.format(oi.unit_price)}/bag`
          const newSummaryOrderItemPrice = document.createElement('span')
          newSummaryOrderItemPrice.classList.add("ml-8")
          newSummaryOrderItemPrice.dataset.billingTarget = "SummaryOrderItemPrice"
          newSummaryOrderItemPrice.innerHTML = this.currencyFormatter.format(oi.total_price)
          newSummaryOrderItem.appendChild(newSummaryOrderItemTitle)
          newSummaryOrderItem.appendChild(newSummaryOrderItemPrice)
          this.SummaryOrderItemsContainerTarget.appendChild(newSummaryOrderItem)
        }
      })

      this.SummaryOrderItemTargets.map((soit) => {
        if (orderItems.find((oi) => oi.id == soit.dataset.orderItemId)) {
          // do nothing
        } else {
          // remove order item from summary
          soit.remove()
        }
      })

      const newSubtotal = responseJson.order.subtotal
      this.SummarySubtotalTarget.innerHTML = this.currencyFormatter.format(newSubtotal)

      const newTotal = responseJson.order.total
      const cardFee = responseJson.order.card_fee
      const result = responseJson.order.payment_method == "credit_card" ? newTotal + cardFee : newTotal
      this.SummaryTotalAmountTarget.innerHTML = this.currencyFormatter.format(result)
    } else {
      alert("There was an error setting the payment method selection. Please try again or contact us for support")
    }
  }

}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    // Set the first tab as active by default
    if (this.tabTargets.length > 0) {
      this.activate(this.tabTargets[0])
    }

    this.handleHash()
    window.addEventListener("hashchange", this.handleHash.bind(this))
  }

  select(event) {
    event.preventDefault()
    this.activate(event.currentTarget)
  }

  activate(tab) {
    // Remove active styles from all tabs and hide all contents
    this.tabTargets.forEach(t => {
      t.classList.remove("text-h85-blue-500", "border-h85-yellow-500")
      t.classList.add("text-gray-500", "border-transparent")
    })

    this.contentTargets.forEach(content => {
      content.classList.add("hidden")
    })

    // Activate selected tab and show content
    tab.classList.remove("text-gray-500", "border-transparent")
    tab.classList.add("text-h85-blue-500", "border-h85-yellow-500")

    // Get the tab ID from the data attribute
    const tabId = tab.dataset.tabId
    // Find the matching content and show it
    const content = this.contentTargets.find(c => c.id === tabId)
    if (content) {
      content.classList.remove("hidden")
    }
  }

  handleHash() {
    const hash = window.location.hash.substring(1)
    if (hash) {
      const tab = this.tabTargets.find(t => t.dataset.tabHashAnchor === hash)
      if (tab) {
        setTimeout(() => {
          tab.click();
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }
  }
}
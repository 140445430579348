import Cookies from 'js-cookie'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "ShippingForm", 
    "ShippingPrice", 
    "DestinationZipcodeInput", 
    "SubmitButton"
  ]

  static values = { localPickup: Boolean }

  connect() {
    if (this.localPickupValue === true) {
      this.ShippingFormTarget.classList.add('hidden')
    } else {
      this.ShippingFormTarget.classList.remove('hidden')
    }

    this.initCustomerZipcode()

    this.onDeliveryMethodChange({ currentTarget: { value: this.localPickupValue.toString() } })
  }

  initCustomerZipcode() {
    if (Cookies.get('admin_selected_customer')) {
      const customer = JSON.parse(Cookies.get('admin_selected_customer'))
      console.log('customer', customer)

      if (this.hasDestinationZipcodeInputTarget) {
        setTimeout(() => {
          this.DestinationZipcodeInputTarget.value = customer.most_recent_order.shipping_zipcode
          this.DestinationZipcodeInputTarget.dispatchEvent(new Event('keyup'))
        }, 1000)
      }
    }
  }

  onDeliveryMethodChange(e) {
    const selectedValue = (e.currentTarget.value === "true")

    this.localPickupValue = selectedValue
    
    if (selectedValue) {
      this.ShippingFormTarget.classList.add('hidden')
      this.ShippingPriceTarget.innerHTML = "$0"
      this.SubmitButtonTarget.innerHTML = "Continue to Payment"
    } else {
      this.ShippingFormTarget.classList.remove('hidden')
      this.ShippingPriceTarget.innerHTML = "Calculated in Next Step"
      const cookieZipcode = Cookies.get('destination_zipcode')
      if (cookieZipcode) {
        this.DestinationZipcodeInputTarget.value = cookieZipcode
      }
      this.SubmitButtonTarget.innerHTML = "Find Shipping Price"
    }
  }

  onZipcodeChange(e) {
    Cookies.set('destination_zipcode', e.currentTarget.value)
  }

}
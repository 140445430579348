import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['price', 'input'];

  connect() {
    this.toggleInput(false);
  }

  toggleInput(show) {
    if (this.hasPriceTarget) {
      this.priceTarget.classList.toggle('hidden', show);
    }
    if (this.hasInputTarget) {
      this.inputTarget.classList.toggle('hidden', !show);
    }
  }

  editPrice() {
    this.toggleInput(true);
    this.inputTarget.focus();
  }

  async updatePrice(e) {
    e.preventDefault();

    const existingPrice = parseFloat(this.priceTarget.innerHTML.replace('$', '').replace(',', ''));
    const newPrice = this.inputTarget.value;

    const request = new FetchRequest('PATCH', '/update_shipping_price', { body: {shipping_price: newPrice}, responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      this.priceTarget.innerHTML = `$${parseFloat(newPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      this.updateOrderTotal(existingPrice, newPrice)
    } else {
      const responseJson = await response.json
      alert('Something went wrong. Errors: ' + responseJson.errors.join(', '))
    }

    this.toggleInput(false);
  }

  updateOrderTotal(existingPrice, newPrice) {
    let orderTotal = document.querySelector('[data-shipping-target="SummaryTotal"]');
    if (!orderTotal) {
      orderTotal = document.querySelector('[data-billing-target="SummaryTotalAmount"]');
    }

    if (orderTotal) {
      const orderTotalValue = parseFloat(orderTotal.innerHTML.replace('$', '').replace(',', ''));
      const orderTotalValueNew = orderTotalValue - existingPrice + parseFloat(newPrice);
      orderTotal.innerHTML = `$${orderTotalValueNew.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
  }
}

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

// Start Stimulus application
const application = Application.start()

// Register controllers using improved approach for Turbo compatibility
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// Add debug logging for controller lifecycle in development
if (import.meta.env.MODE === 'development') {
  application.debug = true
}

// Make application available globally for debugging
window.Stimulus = application
import "@hotwired/turbo-rails";
import "~/controllers";
import * as Sentry from "@sentry/browser";

import "../stylesheets/index.sass";

document.addEventListener("turbo:load", (event) => {
  const target = event.target;
  executeInlineScript(target);
});

// Sentry
if (window.Sentry && import.meta.env.VITE_RAILS_ENV !== 'development' && import.meta.env.VITE_RAILS_ENV !== 'test') {
  Sentry.onLoad(function() {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_JS_DSN,
      environment: import.meta.env.VITE_RAILS_ENV,
      maxBreadcrumbs: 50,
      attachStacktrace: true,
      integrations: [
        Sentry.replayIntegration(),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  })
}

// Improved script executor that's safer with Turbo
function executeInlineScript(element) {
  const scripts = element.querySelectorAll("script:not([data-turbo-executed])");
  if (scripts.length > 0) {
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      // Mark as executed to prevent duplicate execution
      newScript.setAttribute("data-turbo-executed", "true");

      // Copy all attributes from the original script
      Array.from(script.attributes).forEach(attr => {
        if (attr.name !== "src") { // Handle src separately
          newScript.setAttribute(attr.name, attr.value);
        }
      });

      // Handle src scripts vs inline scripts
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.innerHTML = script.innerHTML;
      }

      // Replace the original script
      script.parentNode.replaceChild(newScript, script);
    });
  }
}

// Add a helper to correctly handle Turbo navigation
document.addEventListener("turbo:before-render", () => {
  // Clean up any resources that need to be released before new page render
});

// OpenReplay
// if (import.meta.env.VITE_OPEN_REPLAY_PROJECT_KEY) {
//   const tracker = new Tracker({
//     projectKey: import.meta.env.VITE_OPEN_REPLAY_PROJECT_KEY,
//     ingestPoint: import.meta.env.VITE_OPEN_REPLAY_URL,
//     onStart: ({ sessionToken, sessionID }) => {
//       // This is to link from OpenReplay -> Sentry
//       Sentry.setTag("openReplaySessionToken", sessionToken);

//       // This is to link from Sentry -> OpenReplay (requires tracker v3.6.0+)
//       Sentry.setTag("openReplaySessionURL", tracker.getSessionURL())
//     },
//   })
//   tracker.start()
// }